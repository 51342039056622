import React from 'react';
     
import Footer from './Footer';
import '../styles/TermsAndConditions.css'; // Ensure you create this CSS file for styling

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions-container">
                 
            <div className="terms-and-conditions-content">
                <h1>Terms and Conditions</h1>
                <p>
                    Welcome to our Terms and Conditions page. These terms outline the rules and regulations for using our website. By accessing or using our site, you agree to comply with these terms. If you disagree with any part of these terms, you must not use our site.
                </p>

                <h2>1. Acceptance of Terms</h2>
                <p>
                    By using our website, you agree to these Terms and Conditions and our Privacy Policy. We may update these terms from time to time, and you should review them periodically. Your continued use of our site constitutes acceptance of any changes.
                </p>

                <h2>2. Use of the Website</h2>
                <p>
                    You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of others. You must not use the site to transmit any harmful or illegal content or to interfere with the operation of the site.
                </p>

                <h2>3. User Accounts</h2>
                <p>
                    If you create an account on our website, you are responsible for maintaining the confidentiality of your account details and for any activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
                </p>

                <h2>4. Intellectual Property</h2>
                <p>
                    All content on our website, including text, graphics, logos, and images, is the property of our company or its content suppliers and is protected by intellectual property laws. You may not use any content from our site without our express written permission.
                </p>

                <h2>5. Limitation of Liability</h2>
                <p>
                    Our company shall not be liable for any damages arising from the use or inability to use our website, including but not limited to direct, indirect, incidental, or consequential damages. We do not guarantee the accuracy or completeness of the information on our site.
                </p>

                <h2>6. Governing Law</h2>
                <p>
                    These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which our company operates. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.
                </p>

                <h2>7. Coin to Naira Conversion</h2>
                <p>
                    The following conversion rates apply:
                </p>
                <ul>
                    <li>10,000 coins = 1,000 Naira</li>
                    <li>20,000 coins = 2,000 Naira</li>
                    <li>30,000 coins = 3,000 Naira</li>
                    <li>40,000 coins = 4,000 Naira</li>
                    <li>50,000 coins = 5,000 Naira</li>
                    {/* Add more as needed */}
                </ul>
                <p>
                    Please note that withdrawals will be processed every Friday. Ensure that your account details are accurate to avoid any delays.
                </p>

                <p>
                    If you have any questions about these Terms and Conditions, 
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsAndConditions;
