import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { jsPDF } from "jspdf";
import '../styles/Raffle.css';

// Notification function
const notify = (type, message) => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'info':
            toast.info(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        case 'error':
            toast.error(message);
            break;
        default:
            toast(message);
    }
};

const Raffle = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const [ticketNumber, setTicketNumber] = useState('');
    const [paymentStatus, setPaymentStatus] = useState(false);
    const navigate = useNavigate();

    const handleRegister = () => {
        if (name && email && phone) {
            setIsRegistered(true);
            notify('success', 'You are successfully registered for the raffle!');
        } else {
            notify('error', 'Please fill in all fields!');
        }
    };

    const handlePayment = () => {
        if (isRegistered) {
            // Simulate a payment process
            setPaymentStatus(true);
            generateTicket();  // Generate ticket after successful payment
        } else {
            notify('warning', 'Please register first!');
        }
    };

    const generateTicket = () => {
        // Generate a random ticket number
        const ticket = `RAFFLE-${Math.floor(Math.random() * 1000000)}`;
        setTicketNumber(ticket);

        // Generate PDF with the ticket details
        const doc = new jsPDF();
        doc.setFont("helvetica", "normal");
        doc.setFontSize(20);
        doc.text('Raffle Ticket', 105, 40, null, null, 'center');
        doc.setFontSize(14);
        doc.text(`Name: ${name}`, 20, 60);
        doc.text(`Email: ${email}`, 20, 70);
        doc.text(`Phone: ${phone}`, 20, 80);
        doc.text(`Ticket Number: ${ticket}`, 20, 90);
        doc.text(`Payment Status: ${paymentStatus ? 'Paid' : 'Pending'}`, 20, 100);
        doc.save(`Raffle_Ticket_${ticket}.pdf`);

        notify('success', 'Your raffle ticket has been generated!');
    };

    return (
        <div className="raffle-container">
            <div className="raffle-form">
                <h2>Register for the Raffle</h2>
                <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <button className="register-btn" onClick={handleRegister}>Register</button>
            </div>

            {isRegistered && !paymentStatus && (
                <div className="payment-section">
                    <h3>Payment Section</h3>
                    <p>Click below to proceed with the payment:</p>
                    <button className="payment-btn" onClick={handlePayment}>Pay Now</button>
                </div>
            )}

            {paymentStatus && ticketNumber && (
                <div className="ticket-section">
                    <h3>Your Raffle Ticket</h3>
                    <p>Your ticket number is <strong>{ticketNumber}</strong></p>
                    <p>We have sent you the ticket in PDF format. Check your downloads!</p>
                </div>
            )}

            <ToastContainer />
        </div>
    );
};

export default Raffle;
