import React, { useState, useEffect } from 'react';
import { ref, get, query, orderByValue, equalTo } from 'firebase/database';
import { auth, db } from '../firebase/config'; // Ensure Firebase is properly configured
     
import '../styles/ReferFriend.css';
import Footer from './Footer';

const ReferralFriend = () => {
    const [referralLink, setReferralLink] = useState('');
    const [referrals, setReferrals] = useState([]);

    useEffect(() => {
        const fetchReferralLink = async () => {
            const userId = auth.currentUser.uid;
            const userReferralCode = await getUserReferralCode(userId);
            if (userReferralCode) {
                setReferralLink(`${window.location.origin}?referral=${userReferralCode}`);
                await fetchReferrals(userReferralCode);
            }
        };

        fetchReferralLink();
    }, []);

    const getUserReferralCode = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}/referralCode`);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                console.log('Referral code not found for user');
                return null;
            }
        } catch (error) {
            console.error('Error fetching referral code:', error);
            return null;
        }
    };

    const fetchReferrals = async (referralCode) => {
        try {
            // Query users by referral code
            const usersRef = ref(db, 'users');
            const queryRef = query(usersRef, orderByValue(), equalTo(referralCode));

            const snapshot = await get(queryRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                const referralList = Object.keys(data).map(userId => ({
                    id: userId,
                    ...data[userId]
                }));
                setReferrals(referralList);
            } else {
                console.log('No referrals found for this code');
                setReferrals([]);
            }
        } catch (error) {
            console.error('Error fetching referrals:', error);
        }
    };

    const copyReferralLink = () => {
        const referralLinkInput = document.getElementById('referralLinkInput');
        if (referralLinkInput) {
            referralLinkInput.select();
            document.execCommand('copy');
            alert('Referral link copied to clipboard!');
        }
    };

    return (
        <div>
                 
            <div className="refer-friend-container">
                <h2>Refer a Friend</h2>
                <div className="referral-link-container">
                    <p>Share this link to refer a friend:</p>
                    <div className="referral-link">
                        <input
                            type="text"
                            id="referralLinkInput"
                            value={referralLink}
                            readOnly
                        />
                        <button className="copy-button" onClick={copyReferralLink}>
                            Copy
                        </button>
                    </div>
                </div>
               

                <div className="referral-table-container">
                    <h3>Users Who Registered Using Your Referral Code</h3>
                    <table className="referral-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {referrals.length > 0 ? (
                                referrals.map(referral => (
                                    <tr key={referral.id}>
                                        <td>{referral.name}</td>
                                        <td>{referral.email}</td>
                                        <td>{referral.phone}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No referrals found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ReferralFriend;
