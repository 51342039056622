import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/config.js';
import '../styles/Login.css';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const navigateToRegister = () => navigate('/register');
    const navigateToForgotPassword = () => navigate('/forgot-password');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/home');
        } catch (error) {
            setError('Invalid credentials. Please try again.');
            console.error('Error signing in:', error);
        }
    };

    return (
        <div className="login-container">
            {/* Tap Naira Branding */}
            <header className="login-header">
                <h1 className="brand-title">Tap Naira</h1>
            </header>

            {/* Login Card */}
            <div className="login-card">
                <h2 className="login-heading">Welcome Back</h2>
                <p className="login-subtext">Please log in to continue</p>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleLogin} className="login-form">
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <button type="submit" className="login-btn">
                        Login
                    </button>
                </form>
                <div className="login-options">
                    <button onClick={navigateToForgotPassword} className="secondary-btn">
                        Forgot Password
                    </button>
                    <button onClick={navigateToRegister} className="secondary-btn">
                        Register
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Login;
