import React, { useState, useEffect } from 'react';
import { ref, get, remove } from 'firebase/database';
import { deleteUser } from 'firebase/auth';
import { db, auth } from '../firebase/config'; // Ensure this path is correct
import Modal from 'react-modal'; // You'll need to install react-modal package
     
import Footer from './Footer';

const Settings = () => {
    const [username, setUsername] = useState('');
    const [totalCoins, setTotalCoins] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                const userRef = ref(db, 'users/' + userId);

                try {
                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        setUsername(userData.name);
                        setTotalCoins(userData.totalCoins || 0);
                    } else {
                        console.log('No such user document!');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    const handleDeleteAccount = async () => {
        if (!auth.currentUser) {
            setError('User is not authenticated.');
            return;
        }

        try {
            const userId = auth.currentUser.uid;

            // Delete user from Firebase Realtime Database
            const userRef = ref(db, 'users/' + userId);
            await remove(userRef);

            // Delete user from Firebase Authentication
            await deleteUser(auth.currentUser);

            // Optionally, redirect user after successful deletion
            // e.g., navigate('/login');

            // Close the modal
            setModalIsOpen(false);
        } catch (error) {
            console.error('Error deleting user account:', error);
            setError('Failed to delete account.');
        }
    };

    return (
        <div className="settings-container">
                 
            <div className="settings-content">
                <h2>User Settings</h2>
                <p><strong>Username:</strong> {username}</p>
                <p><strong>Total Coins:</strong> {totalCoins}</p>
                <button onClick={() => setModalIsOpen(true)}>Delete Account</button>

                {/* Modal for confirmation */}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    contentLabel="Confirm Account Deletion"
                    className="modal"
                    overlayClassName="overlay"
                >
                    <h3>Are you sure you want to delete your account?</h3>
                    <button onClick={handleDeleteAccount}>Yes, Delete</button>
                    <button onClick={() => setModalIsOpen(false)}>Cancel</button>
                    {error && <div className="error">{error}</div>}
                </Modal>
            </div>
            <Footer />
        </div>
    );
};

export default Settings;
