import React, { useState } from 'react';
import '../styles/ForgotPassword.css'; // Import external CSS file

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        if (!email) {
            setError('Please enter your email address.');
            return;
        }

        // Simulate password reset logic
        setSuccess(true);
    };

    return (
        <div className="forgot-password-container">
            <header className="forgot-password-header">
                <h1 className="brand-title">Tap Naira</h1>
            </header>
            <div className="forgot-password-card">
                <h2 className="forgot-password-heading">Forgot Password</h2>
                <p className="forgot-password-subtext">Enter your email to reset your password</p>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">Password reset link sent to your email.</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Email Address</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <button type="submit" className="forgot-password-btn">Reset Password</button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
