import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Landing.css';

const Landing = () => {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    };

    return (
        <div className="landing-container">
            {/* Welcome Section */}
            <header className="welcome-section">
                <h1 className="welcome-title">Welcome to Tap Naira!</h1>
                <p className="welcome-subtext">
                    Experience the easiest way to earn money while having fun.
                </p>
                <button className="cta-btn" onClick={handleLogin}>Get Started Now</button>
            </header>

            {/* Raffle Animation */}
            <section className="raffle-highlight-section">
                <div className="raffle-ticket-animation">
                    <p className="raffle-ticket-text">
                        🎄 Join the Christmas Raffle for a chance to win 5 Million Naira! 🎁
                    </p>
                </div>
            </section>

            {/* Hero Section */}
            <section className="landing-section hero-section">
                <h2 className="landing-title">Earn Money While Having Fun!</h2>
                <p className="landing-subtitle">
                    Watch exciting videos, join our forum, and participate in the Christmas Raffle to win big!
                </p>
                <button className="cta-btn" onClick={handleLogin}>Learn More</button>
            </section>

            {/* Features Section */}
            <section className="landing-section features-section">
                <h2 className="section-title">Why Choose Tap Naira?</h2>
                <ul className="features-list">
                    <li>💸 Instant Rewards</li>
                    <li>🎄 Christmas Raffle Fun</li>
                    <li>✅ No Hidden Fees</li>
                    <li>🔒 Safe and Secure</li>
                    <li>🎓 Money-Making Advice</li>
                </ul>
            </section>

            {/* Raffle Section */}
            <section className="landing-section raffle-section">
                <h2 className="section-title">🎄 Special Christmas Raffle 🎄</h2>
                <p className="raffle-description">
                    Register for just <strong>1,000 Naira</strong> and stand a chance to win:
                </p>
                <ul className="raffle-prizes">
                    <li><strong>First Prize:</strong> 5 Million Naira</li>
                    <li><strong>Second Prize:</strong> 2 Million Naira</li>
                    <li><strong>Third Prize:</strong> 1 Million Naira</li>
                    <li>💰 Plus: 50 x 100,000 Naira consolation prizes!</li>
                </ul>
                <button className="cta-btn" onClick={handleLogin}>Join the Raffle</button>
            </section>

            {/* How It Works Section */}
            <section className="landing-section how-it-works-section">
                <h2 className="section-title">How It Works</h2>
                <ul className="how-it-works-list">
                    <li>🔑 <strong>Sign Up</strong> and link your Nitre account.</li>
                    <li>📺 <strong>Watch Videos</strong> and earn instantly.</li>
                    <li>🎁 <strong>Join the Raffle</strong> for big rewards.</li>
                    <li>💼 <strong>Explore Opportunities</strong> in the Buy and Sellers Forum.</li>
                </ul>
            </section>
        </div>
    );
};

export default Landing;
