import React from 'react';
     
import Footer from './Footer';
import '../styles/PrivacyPolicy.css'; // Ensure you create this CSS file for styling

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
                 
            <div className="privacy-policy-content">
                <h1>Privacy Policy</h1>
                <p>
                    Welcome to our Privacy Policy page. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
                </p>
                
                <h2>1. Information We Collect</h2>
                <p>
                    We may collect personal information such as your name, email address, and any other details you provide when you use our services. Additionally, we may collect non-personal information such as your IP address, browser type, and usage data to improve our services.
                </p>

                <h2>2. How We Use Your Information</h2>
                <p>
                    The information we collect is used to provide and improve our services, communicate with you, and ensure that our services are secure and effective. We may also use your information to send you updates and promotional materials, with your consent.
                </p>

                <h2>3. How We Protect Your Information</h2>
                <p>
                    We implement a variety of security measures to protect your personal information. These measures include encryption, secure servers, and access controls. However, no method of transmission over the internet or electronic storage is completely secure.
                </p>

                <h2>4. Sharing Your Information</h2>
                <p>
                    We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as required by law or as necessary to provide our services.
                </p>

                <h2>5. Your Choices</h2>
                <p>
                    You have the right to access, correct, or delete your personal information. You can also opt-out of receiving promotional communications from us at any time by following the instructions in those communications.
                </p>

                <h2>6. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </p>

               
                <p>
                    If you have any questions about this Privacy Policy or our data practices
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
