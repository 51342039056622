// App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import Home from './components/Home';
import logo from './crown.png'; // Replace with your logo path
import WithdrawForm from './components/WithdrawForm';
import Footer from './components/Footer';
import GetCoins from './components/GetCoins'; // Import GetCoins component
import ReferFriend from './components/ReferFriend';
import Earn from './components/Earn';
import Forum from './components/Forum';
import News from './components/News';
import Raffle from './components/Raffle';
import History from './components/History';

import Contact from './components/Contact';
import Settings from './components/Settings';
import BankVerification from './components/BankVerification';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Landing from './components/Landing';

const App = () => {
    return (
        <Router>
            <div className="App">
               {/*<img src={logo} alt="Logo" className="App-logo">*/} 
                <Routes>
                   
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/withdraw" element={<WithdrawForm />} />
                    <Route path="/bank" element={<BankVerification/>} />
                    <Route path="/history" element={<History />} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="/settings" element={<Settings/>} />
                    <Route path="/refer" element={<ReferFriend />} />
                    <Route path="/forum" element={<Forum/>} />
                    <Route path="/earn" element={<Earn/>} />
                    <Route path="/news" element={<News/>} />
                    <Route path="/raffle" element={<Raffle/>} />
                    <Route path="/earn" element={<Earn/>} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsAndConditions/>} />
                    <Route path="/get-coins" element={<GetCoins />} /> {/* Add GetCoins route */}
                    <Route path="/bank" element={BankVerification} />
                </Routes>
               
            </div>
        </Router>
    );
};

export default App;
