import React, { useState } from 'react';
import '../styles/News.css';

const News = () => {
    const [articles] = useState([
        { id: 1, title: 'New Raffle Feature Launched!', date: '2024-12-01', summary: 'We have added a new feature to improve your experience!' },
        { id: 2, title: 'Earn More with Referral Program!', date: '2024-11-28', summary: 'Maximize your earnings through our exciting referral program.' },
    ]);

    return (
        <div className="news-container">
            <div className="news-header">
                <h2>Latest News</h2>
                <p>Stay updated with our latest announcements and features.</p>
            </div>

            <div className="news-articles">
                {articles.map(article => (
                    <div key={article.id} className="news-article">
                        <h3>{article.title}</h3>
                        <p className="date">{article.date}</p>
                        <p>{article.summary}</p>
                        <button className="read-more-btn">Read More</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default News;
