import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set, get, update } from 'firebase/database';
import { auth, db } from '../firebase/config';
import { v4 as uuidv4 } from 'uuid';
import '../styles/Register.css';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const { name, email, phone, password } = formData;
        if (!name || !email || !phone || !password) {
            return 'All fields are required';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const validationError = validateForm();
        if (validationError) {
            setLoading(false);
            setError(validationError);
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            const referralCode = uuidv4();

            const searchParams = new URLSearchParams(window.location.search);
            const referrerCode = searchParams.get('referral');

            const totalCoins = 100;
            const nairaEquivalent = totalCoins * 0.1;

            await set(ref(db, `users/${user.uid}`), {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                totalCoins: totalCoins,
                naira: nairaEquivalent,
                referralCode: referralCode,
                referralCoins: 0,
                referredBy: referrerCode,
            });

            if (referrerCode) {
                const referrerSnapshot = await get(ref(db, `users`));
                if (referrerSnapshot.exists()) {
                    const users = referrerSnapshot.val();
                    const referrer = Object.values(users).find(user => user.referralCode === referrerCode);
                    if (referrer) {
                        const referrerId = Object.keys(users).find(key => users[key].referralCode === referrerCode);
                        const referrerRef = ref(db, `users/${referrerId}`);
                        await update(referrerRef, {
                            referralCoins: (referrer.referralCoins || 0) + 100,
                            referrals: {
                                [user.uid]: true,
                                ...referrer.referrals,
                            },
                        });
                    }
                }
            }

            setLoading(false);
            navigate('/home');
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    };

    return (
        <div className="register-container">
            <header className="register-header">
                <h1 className="brand-title">Tap Naira</h1>
            </header>
            <div className="register-card">
                <h2 className="register-heading">Create an Account</h2>
                <p className="register-subtext">Join us and start earning today</p>
                {error && <p className="error">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your full name"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            id="phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Enter your phone number"
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Create a password"
                            required
                        />
                    </div>
                    <button type="submit" className="register-btn" disabled={loading}>
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Register;
