import React from 'react';
import '../styles/Earn.css';

const Earn = () => {
    return (
        <div className="earn-container">
            <div className="earn-header">
                <h2>Ways to Earn</h2>
                <p>Maximize your earnings through our platform</p>
            </div>

            <div className="earn-options">
                <div className="earn-option">
                    <h3>Referral Program</h3>
                    <p>Earn rewards by referring friends to join the raffle platform.</p>
                </div>
                <div className="earn-option">
                    <h3>Task Completion</h3>
                    <p>Complete simple tasks and earn points that you can convert into prizes.</p>
                </div>
                <div className="earn-option">
                    <h3>Special Offers</h3>
                    <p>Take advantage of limited-time offers to earn additional rewards!</p>
                </div>
            </div>

            <div className="earn-footer">
                <p>Start earning today!</p>
            </div>
        </div>
    );
};

export default Earn;
