import React, { useState } from 'react';
import '../styles/Forum.css';

const Forum = () => {
    const [posts, setPosts] = useState([
        { id: 1, title: 'How to participate in the raffle?', user: 'John Doe', replies: 5 },
        { id: 2, title: 'What is the payment process?', user: 'Jane Smith', replies: 2 },
    ]);
    const [newPost, setNewPost] = useState('');

    const handlePostSubmit = () => {
        if (newPost.trim()) {
            setPosts([...posts, { id: posts.length + 1, title: newPost, user: 'User', replies: 0 }]);
            setNewPost('');
        }
    };

    return (
        <div className="forum-container">
            <div className="forum-header">
                <h2>Community Forum</h2>
                <p>Join discussions, ask questions, and share your thoughts!</p>
            </div>

            <div className="forum-posts">
                {posts.map(post => (
                    <div key={post.id} className="forum-post">
                        <h3>{post.title}</h3>
                        <p>Asked by: {post.user}</p>
                        <p>{post.replies} replies</p>
                    </div>
                ))}
            </div>

            <div className="forum-new-post">
                <textarea
                    placeholder="Have a question or topic to discuss?"
                    value={newPost}
                    onChange={(e) => setNewPost(e.target.value)}
                />
                <button onClick={handlePostSubmit}>Post</button>
            </div>
        </div>
    );
};

export default Forum;
