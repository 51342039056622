import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, get, onValue } from 'firebase/database';
import { db, auth } from '../firebase/config';
import '../styles/Home.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Notification function
const notify = (type, message) => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'info':
            toast.info(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        case 'error':
            toast.error(message);
            break;
        default:
            toast(message);
    }
};

const Home = () => {
    const [totalCoins, setTotalCoins] = useState(0);
    const [naira, setNaira] = useState(0);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (auth.currentUser) {
                const userId = auth.currentUser.uid;
                const userRef = ref(db, 'users/' + userId);

                try {
                    const snapshot = await get(userRef);
                    if (snapshot.exists()) {
                        const userData = snapshot.val();
                        setUsername(userData.name);
                        setTotalCoins(userData.totalCoins || 0);
                        setNaira(userData.naira || 0);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();

        if (auth.currentUser) {
            const userId = auth.currentUser.uid;
            const userRef = ref(db, 'users/' + userId);

            const unsubscribe = onValue(userRef, (snapshot) => {
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    setUsername(userData.name);
                    setTotalCoins(userData.totalCoins || 0);
                    setNaira(userData.naira || 0);
                }
            });

            return () => unsubscribe();
        }
    }, []);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error.message);
        }
    };

    // Handle navigation on card clicks
    const handleAction = (action) => {
        switch (action) {
            case 'earn':
                navigate('/earn');
                break;
            case 'raffle':
                navigate('/raffle');
                break;
            case 'news':
                navigate('/news');
                break;
            case 'forum':
                navigate('/forum');
                break;
            default:
                break;
        }
    };

    return (
        <div className="home-container">
            {/* Header Section */}
            <div className="header">
                <h1>Welcome Back, {username}!</h1>
                <p>Your personal dashboard for tracking coins and balance</p>
            </div>

            {/* Raffle Highlight Section */}
            <section className="raffle-highlight-section">
                <div className="raffle-ticket-animation">
                    <p className="raffle-ticket-text">
                        🎄 Join the Christmas Raffle for a chance to win 5 Million Naira! 🎁
                    </p>
                </div>
            </section>

            {/* Dashboard Section */}
            <div className="dashboard">
                <div className="dashboard-item">
                    <h3>Total Coins</h3>
                    <p>{totalCoins}</p>
                </div>
                <div className="dashboard-item">
                    <h3>Current Naira Value</h3>
                    <p>₦{naira.toFixed(2)}</p>
                </div>
            </div>

            {/* Main Action Cards */}
            <div className="action-cards">
                <div className="card" onClick={() => handleAction('earn')}>
                    <h3>Earn and Withdraw</h3>
                    <p>Start earning and withdraw your coins instantly.</p>
                    <button className="action-btn">Earn Now</button>
                </div>
                <div className="card" onClick={() => handleAction('raffle')}>
                    <h3>My Raffle Ticket</h3>
                    <p>Check and redeem your raffle tickets.</p>
                    <button className="action-btn">View Tickets</button>
                </div>
                <div className="card" onClick={() => handleAction('news')}>
                    <h3>Latest News</h3>
                    <p>Stay updated with the latest news and offers.</p>
                    <button className="action-btn">Read More</button>
                </div>
                <div className="card" onClick={() => handleAction('forum')}>
                    <h3>Forum</h3>
                    <p>Join the community to discuss ideas and strategies.</p>
                    <button className="action-btn">Join Forum</button>
                </div>
            </div>

            {/* Logout Button */}
            <button className="logout-btn" onClick={handleLogout}>Log Out</button>

            {/* Footer Section */}
            <footer className="footer">
                <div className="footer-content">
                    <p>&copy; 2024 <a href="https://www.tapnaira.com" target="_blank" rel="noopener noreferrer">www.tapnaira.com</a></p>
                    <div className="footer-links">
                        <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
                        <a href="/terms-and-conditions" className="footer-link">Terms & Conditions</a>
                    </div>
                </div>
            </footer>

            <ToastContainer />
        </div>
    );
};

export default Home;
